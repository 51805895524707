import { graphql } from "gatsby"
import parse from "html-react-parser"
import React, { useEffect, useRef, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Fade from "react-reveal/Fade"
import ArrowDown from "../../assets/images/arrow-down.svg"
import CloseBlack from "../../assets/images/close-black.svg"
import CTA from "../../components/common/CTA"
import handleFormSubmit from "../../components/common/FormBuilder/Form-Function"
import Frame from "../../components/common/frame"
import PopUpForm from "../../components/common/PopUpForm"
import Navigation from "../../components/navigation"
import { useGetRequiredSearchParams } from "../../hooks/useGetRequiredSearchParams"
import "../../styles/pages/result-page.scss"

const formdata = [
  {
    name: "email",
    type: "email",
    label: "email",
    value: "",
    errorMessage: "Please enter a valid email",
  },
]

const ResultPage = data => {
  // console.log("data", data)
  const { title, navigationBlock, newField } = data.data.SuperOps.pages[1]
  const hero = newField[0]
  const middleCard = newField[1]
  const formInfo = newField[2]
  const ctaBoxes = data.data.SuperOps.ctaBoxes
  const [popupVisibility, setPopupVisibility] = useState(false)
  const togglePopup = () => {
    setPopupVisibility(!popupVisibility)
  }
  const moduleData = data.data.SuperOps.pages[0].newField.filter(
    item => item.__typename === "SuperOps_MultipleCard"
  )
  const moduleCardData = data.data.SuperOps.pages[0].newField.filter(
    item => item.__typename !== "SuperOps_MultipleCard"
  )
  const location = data.location.href
  const paramData = useGetRequiredSearchParams(location, "email")
  const email = paramData[0]?.email
  const [resultData, setResultData] = useState(null)

  useEffect(() => {
    if (email) {
      const queryInput = JSON.stringify({
        data: {
          email: email,
          properties:
            "completed,module_name,are_you_achieving_11_20__growth_annually_,do_you_have_an_active_cross_selling_strategy_,what_percentage_of_your_revenue_is_from_cybersecurity_services_,do_you_resolve_95__of_incidents_within_sla_,what_percentage_of_clients_have_achieved_compliance_certification_through_your_support_,is_your_revenue_in_target_verticals_growing_by_at_least_15__,what_percentage_of_your_clients_belong_to_focus_industries_,do_you_create_thought_leadership_content_tailored_to_your_targeted_verticals_,what_percentage_of_your_technician_s_time_is_spent_on_billable_work_,what_is_your_ratio_of_billable_to_non_billable_hours_,what_is_your_current_nps_,is_your_customer_retention_rate_95__or_higher_,do_you_respond_to_clients_within_15_minutes_on_average_,is_your_cloud_service_revenue_growing_by_at_least_20__annually_,what_percentage_of_your_routine_operational_tasks_are_automated_,on_a_scale_of_1_10__how_satisfied_are_your_clients_with_your_remote_work_solutions_,how_many_strategic_partnerships_do_you_currently_have_,what_percentage_of_your_revenue_is_generated_through_partnerships_,how_many_new_markets_or_service_areas_have_you_entered_through_partnerships_,what_is_your_efficiency_improvement_from_analytics_,do_you_provide_monthly_reports_to_all_clients_,are_you_meeting_90__or_more_of_your_internal_kpis_,is_your_homepage_optimized_to_convert_visitors_using_mentioned_best_practices_,are_you_actively_implementing_google_ads_and_seo_strategies_,do_your_ads_campaigns_focus_on_high_performing_keywords_like_business_it_services_,total_report_score",
        },
      })

      let requestOptions = {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        body: queryInput,
      }

      fetch(process.env.LAMBDA_RESULT_FORM_URL, requestOptions)
        .then(response => response.json())
        .then(result => {
          setResultData(result.properties)
        })
        .catch(error => console.error(error))
    }
  }, [email])

  const handleEmailResults = (email, location) => {
    let final_data = [
      {
        name: "email",
        value: email,
      },
      {
        name: "report_page_link",
        value: location,
      },
    ]

    handleFormSubmit(
      final_data,
      process.env.HUBSPOT_RESULT_EMAIL_FORM,
      location,
      "Report Results Form"
    )
  }

  return (
    <div className="resultPage" key={title}>
      <Frame>
        <header>
          <Navigation page="About us" dskScroll={450} newButton />
        </header>
        <section className="result-hero">
          <Container className="Layout-container d-flex flex-wrap">
            <Col lg={6} className="left-col">
              <div className="word-wrap">
                <p className="tag down-to-up-1">{hero.tag}</p>
                <div className="altv2">
                  <h2 className="heading down-to-up-2">{hero.heading.text}</h2>
                  <h6 className="description down-to-up-3">
                    {hero.content.text}
                  </h6>
                </div>
                <button
                  type="button"
                  className="btn btn-primary-new mt32 down-to-up-4"
                  onClick={togglePopup}
                >
                  {middleCard.Heading2[1]}
                </button>
              </div>
            </Col>
            <Col lg={6} className="right-col">
              <div className="img-wrap">
                {resultData?.["total_report_score"] > 0 && (
                  <img
                    src={
                      hero.images2[
                        resultData?.["total_report_score"] >= 70
                          ? 0
                          : resultData?.["total_report_score"] >= 50
                          ? 1
                          : 2
                      ].url
                    }
                    alt="hero-image"
                    className="w-100 down-to-up-1"
                  />
                )}
              </div>
            </Col>

            <Row className="hero-cta">
              <Col lg={5} className="left">
                <div className="word-wrap">
                  {resultData?.["total_report_score"] > 0 && (
                    <p className="subheading down-to-up-1">
                      {middleCard.Heading2[0]}
                    </p>
                  )}
                  <div className="img-line down-to-up-2">
                    <img
                      src={
                        "https://us-west-2.graphassets.com/AsRMKMrtKTFW6TGbr4KgUz/McwqHk5VRniP78WpZ0u6"
                      }
                      alt="line"
                      className="w-100"
                    />
                  </div>
                  {resultData?.["total_report_score"] > 0 && (
                    <p className="heading down-to-up-2">
                      {
                        moduleCardData[4].text[
                          resultData?.["total_report_score"] >= 70
                            ? 0
                            : resultData?.["total_report_score"] >= 50
                            ? 1
                            : 2
                        ]
                      }
                    </p>
                  )}
                </div>
              </Col>
              <Col lg={7} className="right">
                <div className="word-wrap">
                  {resultData?.["total_report_score"] > 0 && (
                    <p className="description down-to-up-1">
                      {parse(
                        moduleCardData[4].content2[
                          resultData?.["total_report_score"] >= 70
                            ? 0
                            : resultData?.["total_report_score"] >= 50
                            ? 1
                            : 2
                        ].html
                      )}
                    </p>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="modules">
          {moduleData.map((module, index) => (
            <React.Fragment key={module.id}>
              <Fade bottom duration="500">
                {/* Render ToggleModule */}
                <ToggleModule
                  module={module}
                  index={index}
                  emailfilterdata={resultData}
                />
              </Fade>

              {/* {index === 2 && (
                <section className="cta normal-cta">
                  <Fade bottom duration="1500">
                    <div className="cta-wrapper">
                      <CTA
                        data={ctaBoxes}
                        className="Layout-container"
                        lgLeft={6}
                        lgRight={3}
                        newButton
                        newDesign
                        isPrimaryPink
                        buttonClassName="primary-new"
                        openPopUp={() => togglePopup()}
                      />
                    </div>
                  </Fade>
                </section>
              )} */}
            </React.Fragment>
          ))}
        </section>

        <section className="cta normal-cta">
          <Fade bottom duration={1000}>
            <CTA
              data={ctaBoxes}
              className="Layout-container"
              lgLeft={6}
              lgRight={3}
              newButton
              newDesign
              isPrimaryPink
              buttonClassName="primary-new"
              openPopUp={() => togglePopup()}
            />
          </Fade>
        </section>
        <div className="form-container">
          <PopUpForm
            visibility={popupVisibility}
            togglePopup={val => {
              setPopupVisibility(val)
            }}
            formData={formdata}
            formName="Report-Result page form"
            endpoint={process.env.HUBSPOT_RESULT_EMAIL_ENDPOINT}
            url={process.env.HUBSPOT_RESULT_EMAIL_URL}
            formTitle={formInfo.Heading2[0]}
            formButtonText={formInfo.text[0]}
            bottomMessage={formInfo.subtext[0]}
            successMsg="Done! Your friend will get the link soon. Want to refer someone else?"
            successBtnText="Refer again"
          />
        </div>
      </Frame>
    </div>
  )
}

const ToggleModule = ({ module, index, emailfilterdata }) => {
  const [isOpen, setIsOpen] = useState(index === 0)
  const [height, setHeight] = useState(index === 0 ? "auto" : "128px")
  const moduleRef = useRef(null)

  const toggleVisibility = () => {
    setIsOpen(prevState => !prevState)
  }

  useEffect(() => {
    if (moduleRef.current && index !== 0) {
      setHeight(isOpen ? `${moduleRef.current.scrollHeight + 48}px` : "128px")
    }
  }, [isOpen])

  return (
    <div
      className={`module d-flex prelative ${isOpen ? "open" : ""}`}
      ref={moduleRef}
      style={{ height: height, transition: "height 0.5s ease-in-out" }}
      onClick={toggleVisibility}
    >
      <div className="toggle">
        <img
          src={isOpen ? CloseBlack : ArrowDown}
          alt={isOpen ? "close" : "open"}
          className="toggle-icon"
        />
      </div>
      {isOpen ? (
        <>
          <Col lg={4} className="number-name">
            <Fade duration={1000}>
              <div className="number-wrap">
                <p className="number">MODULE {index + 1}</p>
              </div>
              <div className="name-wrap">
                <p className="name">
                  {module.cards && module.cards.length > 0
                    ? module.cards[0].cardName
                    : "No Card Name"}
                </p>
              </div>
            </Fade>
          </Col>

          <Col lg={8} className="que-ans">
            <Fade duration={1000}>
              <div className="questions">
                {module.cards[module.cards.length - 2]?.text.map(
                  (questionText, index) => {
                    const modifiedQues = questionText
                      .replaceAll(/[^a-zA-Z0-9]/g, "_")
                      .toLowerCase()

                    // let optionVal
                    let optionIndexCheck
                    let optionVal = emailfilterdata?.[modifiedQues] || null // Fallback to null if no value is found
                    // modulecheck?.map((ele, idx) => {
                    //   if (ele.name === modifiedQues) {
                    //     optionVal = ele.value
                    //   }
                    // })
                    // {

                    // }
                    return (
                      <div className="question" key={index}>
                        <div className="d-flex">
                          <p className="que-num">{index + 1}</p>
                          <p className="que">{questionText}</p>
                        </div>
                        <div className="options">
                          {module.cards[module.cards.length - 2]?.multiOption[
                            index
                          ]?.option.map((option, optionIndex) => {
                            if (optionVal === undefined) {
                              optionVal = null
                            }

                            if (optionVal === option) {
                              optionIndexCheck = optionIndex
                            }
                            return (
                              <div
                                key={`${index}-${optionIndex}`}
                                className="option"
                              >
                                <input
                                  type="radio"
                                  id={`question-${index}`} // Group radio buttons per question
                                  value={option}
                                  checked={optionVal === option}
                                />
                                <label
                                  className={`label ${
                                    optionVal === option ? "label-check" : ""
                                  }`}
                                >
                                  {option}
                                </label>
                              </div>
                            )
                          })}
                        </div>
                        {optionVal !== null && optionIndexCheck !== undefined && (
                          <div className="que-wrap-line">
                            <p className="feedback">
                              {
                                module.cards[module.cards.length - 1]
                                  ?.multiOption[index]?.option[optionIndexCheck]
                              }
                            </p>
                          </div>
                        )}
                      </div>
                    )
                  }
                )}
              </div>
            </Fade>
          </Col>
        </>
      ) : (
        <>
          <Col lg={4} className="number">
            <Fade duration={1000}>
              <p className="module-number">MODULE {index + 1}</p>
            </Fade>
          </Col>
          <Col lg={8} className="name">
            <Fade duration={1000}>
              <p className="module-name">
                {module.cards && module.cards.length > 0
                  ? module.cards[0].cardName
                  : "No Card Name"}
              </p>
            </Fade>
          </Col>
        </>
      )}
    </div>
  )
}

export default ResultPage

export const query = graphql`
  query resultPage {
    SuperOps {
      pages(where: { title_in: ["Results", "Growth report"] }) {
        title
        navigationBlock {
          name
          slug
          isDropdown
        }

        newField {
          ... on SuperOps_Hero {
            tag
            heading {
              text
            }
            content {
              text
            }
            heroImage {
              url
            }
            images2: images {
              url
            }
          }
          ... on SuperOps_Card {
            cardType
            cardName
            cardDescription
            Heading2: heading
            text
            subtext
            content2: content {
              html
            }
          }
          ... on SuperOps_MultipleCard {
            __typename
            cards {
              cardName
              cardDescription
              slug
              text
              subtext
              links
              image {
                url
              }
              content {
                html
              }
              multiChoice {
                option
              }
              multiOption {
                option
              }
            }
          }
        }
      }
      ctaBoxes(where: { where: "Result Page CTA" }) {
        type
        theme
        customBackgroundColor
        heading {
          html
        }
        description {
          html
        }
        primaryButtonText
        primaryButtonLink
        secondaryButtonText
        secondaryButtonLink
        card {
          cardName
          title
          subtext
          slug
        }
      }
    }
  }
`
